import { useMemo } from 'react'
import { buckets } from '@utils/buckets'
import { CardSearchContentItem } from '../../styles'
import { Divider, Tooltip, Typography } from '@mui/material'
import Link, { LinkProps } from '@components/atoms/Link/Link'
import { AlertEntity } from '@services/nomos_api/entities/alert'

type Props = { value: AlertEntity }

export function DiscoveryCard({ value }: Props): JSX.Element {
  const linkProps = useMemo((): LinkProps => {
    if (['social', 'notices', 'cvm'].includes(value.bucket)) {
      return { href: value.link, target: '_blank' }
    }
    return {
      href: `/${value.bucket}/${value.originId}`,
    }
  }, [value])

  return (
    <div>
      <CardSearchContentItem>
        <Typography
          variant="$font-body-3"
          color="primary.light"
          noWrap
          width="80%"
        >
          {value.name}
        </Typography>

        <Typography
          variant="$font-body-3"
          color="muted.dark"
          noWrap
          width="80%"
        >
          {buckets[value.bucket].name}
        </Typography>

        <Typography
          noWrap
          width="80%"
          variant="$font-body-3"
          color="muted.dark"
        >
          <Tooltip title={value.title || ''} placement="top">
            <Link {...linkProps} label={value.title} />
          </Tooltip>
        </Typography>
      </CardSearchContentItem>
      <Divider />
    </div>
  )
}
