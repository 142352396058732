import { Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { CardRadio } from '@components/organisms/Cards'
import { useContextSelector } from 'use-context-selector'
import { PropositionStatusNotificationContext } from '../../context'

import { Content, InfoLabel } from './styles'

type Props = {
  disabled: boolean
}

export function FormNotificationFrequency({ disabled }: Props) {
  const alertFrequency = useContextSelector(
    PropositionStatusNotificationContext,
    (s) => s.alertFrequency
  )

  const setAlertFrequency = useContextSelector(
    PropositionStatusNotificationContext,
    (s) => s.setAlertFrequency
  )

  const [localAlertFrequency, setLocalAlertFrequency] = useState(alertFrequency)

  useEffect(() => {
    setAlertFrequency(localAlertFrequency)
  }, [localAlertFrequency])

  return (
    <Content>
      <InfoLabel>
        <Typography variant="$font-title-5" color="$color-text-secondary">
          Frequência de Notificação
        </Typography>
      </InfoLabel>

      <CardRadio
        name="frequency"
        disabled={disabled}
        value={localAlertFrequency}
        onChange={(_e, value) => setLocalAlertFrequency(value)}
        options={[
          {
            value: 'realtime',
            title: 'Tempo Real',
            description:
              'Notificação enviada no momento exato em que a proposição é atualizada',
          },
          {
            value: 'daily',
            title: 'Diário',
            description:
              'Notificação enviada diariamente indicando todas as atualizações da proposição',
          },
          // {
          //   value: 'weekly',
          //   title: 'Semanal',
          //   description:
          //     'Notificação enviada semanalmente com as atualizações desta proposição',
          // },
        ]}
      />
    </Content>
  )
}
