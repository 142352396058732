import { Wrapper } from './Tag.styles'
import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { TagEntity } from '@services/nomos_api/entities/tag'
import TypographyWrap from '../TypographyWrap/TypographyWrap'
import DeleteIcon from '@mui/icons-material/Delete'
import { deleteTagApi } from '@services/nomos_api/resources/tags/delete'
import { useAuth } from '@contexts/Auth'
import { NomosProductCodeEnum } from '@enums/NomosProductEnum'
import { useContextSelector } from 'use-context-selector'
import { TagManagerContext } from '@containers/TagManager/context'

type Props = Partial<Pick<TagEntity, 'createdAt' | 'updatedAt' | 'id'>> &
  Required<Pick<TagEntity, 'name' | 'color' | 'ownerId'>> & {
    fullwidth?: boolean
    size?: 'medium' | 'small' | 'tiny'
    onRemove?: (id: string) => void | undefined
    enableDelete?: boolean
  }

function Tag({
  id,
  name,
  color,
  size,
  ownerId,
  fullwidth,
  onRemove,
  enableDelete,
}: Props): JSX.Element {
  const { user } = useAuth()
  const refetchTags = useContextSelector(
    TagManagerContext,
    (s) => s.refetchTags
  )
  const showDelete =
    enableDelete &&
    id &&
    (user?.role === NomosProductCodeEnum.MONITOR_PRO || user?.id === ownerId)

  return (
    <Wrapper
      color={color}
      size={size || 'medium'}
      fullwidth={fullwidth ? 'true' : 'false'}
    >
      <TypographyWrap
        variant={size === 'tiny' ? '$font-body-3' : '$font-body-base'}
        color="$color-text-primary"
      >
        {name}
      </TypographyWrap>
      {onRemove && id && (
        <IconButton sx={{ width: 18, height: 18 }} onClick={() => onRemove(id)}>
          <Close fontSize="small" sx={{ fontSize: 12 }} />
        </IconButton>
      )}
      {showDelete && (
        <IconButton
          sx={{ width: 24, height: 24, marginLeft: 'auto' }}
          onClick={async () => {
            await deleteTagApi(id, user?.id)
            refetchTags()
          }}
        >
          <DeleteIcon fontSize="medium" sx={{ fontSize: 16 }} />
        </IconButton>
      )}
    </Wrapper>
  )
}

Tag.defaultProps = {
  size: undefined,
  fullwidth: undefined,
  onRemove: undefined,
}

export default Tag
