import styled from 'styled-components'
import { styled as styledMaterial } from '@mui/material/styles'
import { theme } from '@theme/index'
import { Box } from '@mui/material'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 48px 0;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const Section = styledMaterial(Box)(() => ({}))

export const EmptyData = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  color: '#9EA5B8',
}))

export const Card = styled.div`
  flex: 1;
  display: flex;
  padding: 16px;
  border-radius: 8px;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #e7e9ed;
`

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;
`

export const CardCounters = styled.div`
  display: flex;
  padding-top: 4px;
  justify-content: space-between;

`

export const CardNumber = styled.span`
  display: flex;
  color: #4070F4;
  font-size: 56px;
  font-weight: 700;
  line-height: 56px;
  margin-left: auto;
  letter-spacing: -0.05em;
  padding-right: 12px;
`

export const BoxDivider = styled.div`
  display: flex;
  flex-direction: column ;
  gap: 32px;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-direction: row ;
  }
`

export const CardInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
`

export const CardContent = styled.div``

export const CardEventsList = styled.div`
  display: flex;
  flex-direction: column;
  
  gap: 20px;

  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f4f5;
  }
  ::-webkit-scrollbar-thumb {
    background: #ced2db;
    border-radius: 6px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #4a8eff50;
  }
  @media (min-width: 768px){
    height: 160px;
  }
`

export const CardSearchList = styled(CardEventsList)`
  gap: 0px;

  @media (min-width: 768px){
    height: 165px;
  }
`

export const CardProceedings = styled(CardEventsList)`
  gap: 0px;

  @media (min-width: 768px){
    height: 300px;
  }
`

export const CardSearch = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const CardSearchHeader = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  gap: 4px;
  background-color: #f9fafc;

  span {
    padding: 16px;
    padding-right: 0px;
  }
`

export const CardSearchContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const CardSearchContentItem = styled.div`
  gap: 4px;
  display: grid;
  align-items: center;
  grid-template-columns: 33% 33% 33%;

  span {
    padding: 8px 24px;
    padding-right: 8px;

    a {
      color: #4070F4;
    }

    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

export const CardTramitationHeader = styled(CardSearchHeader)`
  grid-template-columns: 15% 20% 35% 23% 5%;

  span {
    padding: 16px;
    padding-right: 0px;
  }
`

export const CardTramitationContentItem = styled(CardSearchContentItem)`
  grid-template-columns: 15% 20% 35% 23% 5%;
  cursor: default;

  span {
    padding: 16px;
    padding-right: 0px;
  }
  div {
    overflow: hidden;
    text-overflow: ellipsis;

    svg {
      cursor: pointer;
      size: 32px;
      color: #4070F4;
    }
  }
`
export const CardTramitation = styled(Box)`
  border-radius: 8px;
  border: 1px solid ${theme.palette['$color-border-primary']};
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
