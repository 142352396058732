import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { startSearchEvent } from '@utils/metrics'
import { SearchCountEntity } from '@services/nomos_api/entities/saved_searches'
import { AdvancedSearchType } from '@services/nomos_api/entities/advanced_search'
import { appendPlurals } from '@utils/functions/keyword/serialize'

export const searchCountAPI = async (
  search: AdvancedSearchType,
  buckets: string[],
  modes: string[]
): Promise<SearchCountEntity> => {
  if (
    (search.keywords.or || []).length === 0 &&
    (search.keywords.and || []).length === 0 &&
    (search.keywords.not || []).length === 0
  ) {
    return {} as SearchCountEntity
  }
  if (!search) return {} as SearchCountEntity

  startSearchEvent()
  const { data } = await HTTPClient.post(
    `/search/count?buckets=${buckets.join(',')}&modes=${modes.join(',')}`,
    {
      keywords: appendPlurals(search.keywords),
    }
  )
  return data as SearchCountEntity
}

export const useSearchCount = (
  search: AdvancedSearchType,
  buckets: string[],
  modes: string[]
) =>
  useQuery(
    [
      'search-count',
      JSON.stringify(search),
      buckets.join('-'),
      modes.join('-'),
    ],
    () => searchCountAPI(search, buckets, modes),
    { refetchOnWindowFocus: false }
  )
