/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-inline-styles/no-inline-styles, no-unused-vars, prettier/prettier */
import { useContext, useMemo } from 'react'
import { GeneralPanelContext } from './context'
import { Link, Shimmer } from '@components/atoms'
import { KeyboardArrowRight } from '@mui/icons-material'
import { Typography, Divider, Tooltip, Stack } from '@mui/material'
import { formatDate } from '@utils/functions/formatter/formatter'

import PropositionsSVG from '@assets/icons/propositions.svg'
import StakeholdersSVG from '@assets/icons/stakeholders.svg'
import SavedSearchSVG from '@assets/icons/saved_searches.svg'
import NoDataSVG from '@assets/icons/nodata.svg'
import EventsSVG from '@assets/icons/events.svg'
import FavoriteIcon from '@assets/icons/star.svg'
import OrgansIcon from '@assets/icons/house.svg'
import ProceedingsIcon from '@assets/icons/proceedings.svg'
import colors from '@theme/colors'

import {
  Container,
  Section,
  Content,
  Card,
  CardHeader,
  CardInfo,
  CardCounters,
  CardNumber,
  BoxDivider,
  CardEventsList,
  EmptyData,
  CardSearch,
  CardSearchHeader,
  CardSearchList,
  CardTramitationHeader,
  CardTramitationContentItem,
  CardProceedings,
  CardTramitation,
} from './styles'
import { DiscoveryCard } from './components/DiscoveryCard'
import { MiniEventCard } from '@components/organisms/Cards'
import { EventModal } from '@containers/EventModal'
import { EventEntity } from '@services/nomos_api/entities/event'
import { useModal } from '@contexts/Modal'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

export function GeneralPanelView() {
  const {
    monitor,
    isLoadingMonitor,
    monitorNextEvents,
    eventsLoading,
    monitorDiscoveredSearches,
    searchesLoading,
    monitorProceedingPropositions,
    propositionsLoading,
  } = useContext(GeneralPanelContext)

  const { openModal } = useModal()
  const navigate = useNavigate()

  const handleOpenModal = (event: EventEntity) => {
    openModal(<EventModal event={event} />)
  }

  const handleOpenProposition = (id: string) => {
    navigate(`/propositions/${id}`)
  }

  const countDeputados = useMemo(() => {
    let count = 0
    for (const type in monitor?.count?.stakeholders || {}) {
      if (['all', 'senado', 'eagendas'].includes(type)) continue
      count += monitor.count.stakeholders[type]
    }
    return count
  }, [monitor?.count?.stakeholders])

  return (
    <Container>
      <Content>
        <Section display="grid" gridTemplateColumns="100% 1fr 1fr" gap={4}>
          <BoxDivider>
            <Card>
              <CardHeader>
                <img src={PropositionsSVG} alt="Proposiçoes" />
                <Typography
                  variant="$font-body-1"
                  color="$color-text-secondary"
                >
                  Proposições Monitoradas
                </Typography>
              </CardHeader>
              <CardCounters>
                <Stack gap={1}>
                  <Stack direction="row" gap={1}>
                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      Câmara
                    </Typography>
                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      {monitor?.count?.propositions?.camara}
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      Senado
                    </Typography>
                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      {monitor?.count?.propositions?.senado}
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      Congresso
                    </Typography>

                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      {monitor?.count?.propositions?.congresso}
                    </Typography>
                  </Stack>
                </Stack>

                <Shimmer isLoading={isLoadingMonitor} height={60} width={50}>
                  <CardNumber>
                    {monitor
                      ? Object.values(monitor?.count?.propositions).reduce(
                          (a: number, b: number) => a + b,
                          0
                        )
                      : '-'}
                  </CardNumber>
                </Shimmer>
              </CardCounters>
            </Card>
            <Card>
              <CardHeader>
                <img src={StakeholdersSVG} alt="Stakeholders" />
                <Typography
                  variant="$font-body-1"
                  color="$color-text-secondary"
                >
                  Stakeholders Monitorados
                </Typography>
              </CardHeader>
              <CardCounters>
                <Stack gap={1}>
                  <Stack direction="row" gap={1}>
                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      Senadores
                    </Typography>

                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      {monitor?.count?.stakeholders?.senado || 0}
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      Deputados
                    </Typography>
                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      {countDeputados}
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      Executivo
                    </Typography>
                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      {monitor?.count?.stakeholders?.eagendas || 0}
                    </Typography>
                  </Stack>
                </Stack>
                <Shimmer isLoading={isLoadingMonitor} height={60} width={50}>
                  <CardNumber>
                    {monitor?.count?.stakeholders?.all || 0}
                  </CardNumber>
                </Shimmer>
              </CardCounters>
            </Card>
            <Card>
              <CardHeader>
                <img src={OrgansIcon} alt="Orgãos Monitorados" />
                <Typography
                  variant="$font-body-1"
                  color="$color-text-secondary"
                >
                  Órgãos Monitorados
                </Typography>
              </CardHeader>
              <CardCounters>
                <Stack gap={1}>
                  <Stack direction="row" gap={1}>
                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      Camara
                    </Typography>
                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      {monitor?.count?.organs?.camara || 0}
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      Senado
                    </Typography>
                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      {monitor?.count?.organs?.senado || 0}
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      Executivo
                    </Typography>
                    <Typography
                      variant="$font-body-base"
                      color="$color-text-secondary"
                    >
                      {monitor?.count?.organs?.executivo || 0}
                    </Typography>
                  </Stack>
                </Stack>
                <Shimmer isLoading={isLoadingMonitor} height={60} width={50}>
                  <CardNumber>
                    {(monitor?.count?.organs?.camara || 0) +
                      (monitor?.count?.organs?.senado || 0) +
                      (monitor?.count?.organs?.executivo || 0)}
                  </CardNumber>
                </Shimmer>
              </CardCounters>
            </Card>
          </BoxDivider>
        </Section>

        <Section>
          <Card>
            <CardHeader>
              <img src={FavoriteIcon} alt="Dados Favoritados" />
              <Typography variant="$font-body-1" color="$color-text-secondary">
                Dados Favoritados
              </Typography>
            </CardHeader>
            <CardCounters>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                gap={{ sm: 4 }}
                flexWrap="wrap"
                width="80%"
              >
                <Stack alignItems="flex-start">
                  <Typography
                    lineHeight={2}
                    variant="$font-body-base"
                    color="$color-text-secondary"
                  >
                    Tweets {monitor?.count?.favorites?.social || 0}
                  </Typography>
                  <Typography
                    lineHeight={2}
                    variant="$font-body-base"
                    color="$color-text-secondary"
                  >
                    Discursos {monitor?.count?.favorites?.speeches || 0}
                  </Typography>
                </Stack>
                <Stack alignItems="flex-start">
                  <Typography
                    lineHeight={2}
                    variant="$font-body-base"
                    color="$color-text-secondary"
                  >
                    Diário Oficial da União{' '}
                    {monitor?.count?.favorites?.dou || 0}
                  </Typography>
                  <Typography
                    lineHeight={2}
                    variant="$font-body-base"
                    color="$color-text-secondary"
                  >
                    Diários {monitor?.count?.favorites?.oficial_diary || 0}
                  </Typography>
                </Stack>
                <Stack alignItems="flex-start">
                  <Typography
                    lineHeight={2}
                    variant="$font-body-base"
                    color="$color-text-secondary"
                  >
                    Banco Central {monitor?.count?.favorites?.bacen || 0}
                  </Typography>
                  <Typography
                    lineHeight={2}
                    variant="$font-body-base"
                    color="$color-text-secondary"
                  >
                    CVM {monitor?.count?.favorites?.cvm || 0}
                  </Typography>
                </Stack>
                <Stack alignItems="flex-start">
                  <Typography
                    lineHeight={2}
                    variant="$font-body-base"
                    color="$color-text-secondary"
                  >
                    Agências Reguladoras{' '}
                    {monitor?.count?.favorites?.regulatory_agencies || 0}
                  </Typography>
                  <Typography
                    lineHeight={2}
                    variant="$font-body-base"
                    color="$color-text-secondary"
                  >
                    Receita Federal {monitor?.count?.favorites?.irs || 0}
                  </Typography>
                </Stack>
              </Stack>

              <Shimmer isLoading={isLoadingMonitor} height={60} width={50}>
                <CardNumber>
                  {(monitor?.count?.favorites?.social || 0) +
                    (monitor?.count?.favorites?.dou || 0) +
                    (monitor?.count?.favorites?.bacen || 0) +
                    (monitor?.count?.favorites?.regulatory_agencies || 0) +
                    (monitor?.count?.favorites?.speeches || 0) +
                    (monitor?.count?.favorites?.oficial_diary || 0) +
                    (monitor?.count?.favorites?.cvm || 0) +
                    (monitor?.count?.favorites?.irs || 0)}
                </CardNumber>
              </Shimmer>
            </CardCounters>
          </Card>
        </Section>

        <Section>
          <Card>
            <CardHeader>
              <img src={EventsSVG} alt="Eventos" />

              <Typography variant="$font-body-1" color="$color-text-secondary">
                Eventos Monitorados
              </Typography>

              <div style={{ display: 'flex', marginLeft: 'auto' }}>
                <Shimmer isLoading={isLoadingMonitor} height={56} width={50}>
                  <CardNumber>
                    {monitor ? monitor?.count?.events : '-'}
                  </CardNumber>
                </Shimmer>
              </div>
            </CardHeader>

            <Divider sx={{ m: '16px 0' }} />

            <CardInfo>
              <Typography variant="$font-body-1" color="$color-text-secondary">
                Eventos que acontecerão nos próximos 7 dias
              </Typography>
            </CardInfo>

            <Shimmer isLoading={eventsLoading} height={150} width={400}>
              <CardEventsList>
                {monitorNextEvents?.length > 0 ? (
                  monitorNextEvents.map((item) => (
                    <MiniEventCard
                      event={item}
                      key={item.id}
                      onClick={() => handleOpenModal(item)}
                    />
                  ))
                ) : (
                  <EmptyData>
                    <img src={NoDataSVG} alt="Sem dados" />
                    <span>Não há eventos a serem exibidos</span>
                  </EmptyData>
                )}
              </CardEventsList>
            </Shimmer>
          </Card>
        </Section>

        <Section>
          <Card>
            <CardHeader>
              <img src={SavedSearchSVG} alt="Pesquisas" />

              <Typography variant="$font-body-1" color="$color-text-secondary">
                Pesquisas salvas
              </Typography>

              <div style={{ display: 'flex', marginLeft: 'auto' }}>
                <Shimmer isLoading={isLoadingMonitor} height={56} width={50}>
                  <CardNumber>
                    {monitor ? monitor?.count?.searches : '-'}
                  </CardNumber>
                </Shimmer>
              </div>
            </CardHeader>

            <Divider sx={{ m: '16px 0' }} />

            <CardInfo>
              <Typography variant="$font-body-1" color="$color-text-secondary">
                Descobertas dos últimos 7 dias
              </Typography>
            </CardInfo>

            <Shimmer isLoading={searchesLoading} height={150} width={400}>
              <CardSearchList>
                {monitorDiscoveredSearches?.length > 0 && (
                  <CardSearchHeader>
                    <Typography
                      variant="$font-body-3"
                      color="#0A2156"
                      fontWeight={500}
                    >
                      Pesquisa salva
                    </Typography>
                    <Typography
                      variant="$font-body-3"
                      color="#0A2156"
                      fontWeight={500}
                    >
                      Categoria
                    </Typography>
                    <Typography
                      variant="$font-body-3"
                      color="#0A2156"
                      fontWeight={500}
                    >
                      Nome
                    </Typography>
                  </CardSearchHeader>
                )}
                <CardSearch>
                  {monitorDiscoveredSearches?.length > 0 &&
                    monitorDiscoveredSearches.map((item) => (
                      <DiscoveryCard key={`item-${item.id}`} value={item} />
                    ))}
                </CardSearch>
                {monitorDiscoveredSearches?.length === 0 && (
                  <EmptyData>
                    <img src={NoDataSVG} alt="Sem dados" />
                    <span>Não há pesquisa salva a serem exibidos</span>
                  </EmptyData>
                )}
              </CardSearchList>
            </Shimmer>
          </Card>
        </Section>

        <Section>
          <Card>
            <CardHeader>
              <img src={ProceedingsIcon} alt="Ultimas tramitações" />

              <Typography variant="$font-body-1" color="$color-text-secondary">
                Últimas tramitações
              </Typography>
            </CardHeader>

            <Shimmer isLoading={propositionsLoading} height={150} width={1100}>
              <CardProceedings>
                {monitorProceedingPropositions?.results?.length > 0 && (
                  <CardTramitationHeader>
                    <Typography
                      variant="$font-body-3"
                      color="#0A2156"
                      fontWeight={500}
                    >
                      Proposição
                    </Typography>
                    <Typography
                      variant="$font-body-3"
                      color="#0A2156"
                      fontWeight={500}
                    >
                      Órgão
                    </Typography>
                    <Typography
                      variant="$font-body-3"
                      color="#0A2156"
                      fontWeight={500}
                    >
                      Situação
                    </Typography>
                    <Typography
                      variant="$font-body-3"
                      color="#0A2156"
                      fontWeight={500}
                    >
                      Data da movimentação
                    </Typography>
                  </CardTramitationHeader>
                )}
                <CardSearch>
                  {monitorProceedingPropositions?.results?.length > 0 ? (
                    monitorProceedingPropositions.results.map((item) =>
                      isMobile ? (
                        <CardTramitation
                          key={item.id}
                          onClick={() => handleOpenProposition(item.id)}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              variant="$font-body-base"
                              color="$color-action-link"
                            >
                              {item.title}
                            </Typography>

                            <KeyboardArrowRight
                              sx={{
                                color: colors['$color-action-link'],
                                fontSize: 32,
                              }}
                            />
                          </Stack>
                          <Stack direction="row" gap={1}>
                            <Typography variant="$font-body-4">
                              Última Movimentação:{' '}
                              <Typography variant="$font-body-base">
                                {formatDate(item?.proceedingDate)}
                              </Typography>
                            </Typography>
                          </Stack>
                          <Stack direction="row" gap={1}>
                            <Typography variant="$font-body-4">
                              Situação:{' '}
                              <Typography variant="$font-body-base">
                                {item.situation}
                              </Typography>
                            </Typography>
                          </Stack>
                          <Stack direction="row" gap={1}>
                            <Typography variant="$font-body-4">
                              Orgão:{' '}
                              <Typography variant="$font-body-base">
                                {item.organ?.name ||
                                  item.organ?.initials ||
                                  item.organ ||
                                  ''}
                              </Typography>
                            </Typography>
                          </Stack>
                        </CardTramitation>
                      ) : (
                        <div key={item.id}>
                          <CardTramitationContentItem>
                            <Typography
                              variant="$font-body-3"
                              color="primary.light"
                              noWrap
                              maxWidth="80%"
                            >
                              <Tooltip title={item.summary || ''}>
                                <Link
                                  href={`/propositions/${item.id}`}
                                  label={item.title}
                                />
                              </Tooltip>
                            </Typography>

                            <Typography
                              variant="$font-body-3"
                              color="muted.dark"
                              noWrap
                              maxWidth="80%"
                              textTransform="capitalize"
                            >
                              <Tooltip
                                title={
                                  item.organ?.name ||
                                  item.organ?.initials ||
                                  item.organ ||
                                  ''
                                }
                              >
                                <Link
                                  href={`/organs/${item.organ?.id}`}
                                  label={
                                    item.organ?.initials ||
                                    (item.organ as unknown as string) ||
                                    ''
                                  }
                                />
                              </Tooltip>
                            </Typography>

                            <Typography
                              variant="$font-body-3"
                              color="muted.dark"
                              noWrap
                              maxWidth="80%"
                            >
                              {item.situation}
                            </Typography>

                            <Typography
                              variant="$font-body-3"
                              color="muted.dark"
                              noWrap
                              maxWidth="80%"
                            >
                              {formatDate(item?.proceedingDate)}
                            </Typography>

                            <div>
                              <Link
                                href={`/propositions/${item.id}`}
                                label={<KeyboardArrowRight />}
                              />
                            </div>
                          </CardTramitationContentItem>
                          <Divider />
                        </div>
                      )
                    )
                  ) : (
                    <EmptyData sx={{ m: '20px 0' }}>
                      <img src={NoDataSVG} alt="Sem dados" />
                      <span>
                        Não houve movimentações recentes nas proposições deste
                        painel
                      </span>
                    </EmptyData>
                  )}
                </CardSearch>
              </CardProceedings>
            </Shimmer>
          </Card>
        </Section>
      </Content>
    </Container>
  )
}
