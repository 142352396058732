import { UrlType } from '@type/url'
import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'
import { signatureEvent } from '@utils/metrics'

export const generatePaymentLink = async (token?: string): Promise<UrlType> => {
  signatureEvent()
  const params = []
  if (token) params.push(`token=${token}`)
  const { data } = await HTTPClient.get<UrlType>(`checkout?${params.join('&')}`)
  return data
}

export const usePaymentLink = () =>
  useQuery(['payment-link'], () => generatePaymentLink(), {
    refetchOnWindowFocus: false,
  })
