import HTTPClient from '../..'
import { useQuery } from '@tanstack/react-query'

export const deleteTagApi = async (
  id?: string,
  userId?: string
): Promise<void> => {
  const { data } = await HTTPClient.delete(`tags`, {
    data: {
      tagId: id,
      userId,
    },
  })
  return data
}

export const useDeleteTag = (id: string, userId: string) => {
  return useQuery(
    [`delete-tag-${id}-${userId}`],
    () => deleteTagApi(id, userId),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  )
}
