import _ from 'lodash'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useDrawer } from '@contexts/Drawer'
import { SaveSearchContext } from './context'
import { useSnackbar } from '@contexts/Snackbar'
import { useState, useMemo, useCallback, useEffect } from 'react'
import { SaveSearchEntity } from '@services/nomos_api/entities/saved_searches'
import { removeEmptyArrays } from '@utils/functions/normalizers/remove_empty_array'
import { KeywordConditionsType } from '@services/nomos_api/entities/advanced_search'
import { useCreateMonitorSearch } from '@services/nomos_api/resources/monitor_search/create'
import { MultiSelectChipOption } from '@components/atoms/InputMultiSelectChip/InputMultiSelectChip'
import { useByPassSubscription } from '@hooks/useByPassSubscription'
import { NomosProductCodeEnum } from '@enums/NomosProductEnum'
import { calculatePlurals } from '@utils/functions/keyword/serialize'

const validationSchemaPainelName = Yup.object().shape({
  name: Yup.string()
    .min(5, 'Deve ter no mínimo 5 caracteres')
    .max(30, 'Deve ter no máximo 30 caracteres')
    .required('Nome da pesquisa obrigatória'),
  monitors: Yup.array()
    .min(1, 'Selecione ao menos um monitor')
    .required('Selecione ao menos um monitor'),
  alert: Yup.object({
    destinations: Yup.array()
      .min(1, 'Selecione ao menos um canal de notificação')
      .required('Selecione ao menos um canal de notificação'),
    frequency: Yup.string().required('A seleção da frequência é obrigatória'),
  }),
})

type FormikFormType = {
  name: string | undefined
  keywords: KeywordConditionsType
  buckets: string[]
  monitors?: MultiSelectChipOption[]
}

export type SavedSearchControllerProps = {
  search: SaveSearchEntity
  children: React.ReactChild
}

export function SavedSearchController({
  search,
  children,
}: SavedSearchControllerProps) {
  useByPassSubscription({
    featureTitle: 'Salvar Pesquisa',
    featureSubtitle: `Monitore dados do interesse do seu negócio através de Pesquisas Salvas`,
    featureDescription: `Você será alertado quando novos dados relacionados à sua pesquisa forem encontrados.`,
    requiredRole: NomosProductCodeEnum.MONITOR_PRO,
  })

  const { closeDrawer } = useDrawer()
  const { showSnackbarSuccess, showSnackbarError } = useSnackbar()

  const [step, setStep] = useState('introduction')
  const [activeBucket, setActiveBucket] = useState(
    Object.keys(search?.buckets)[0]
  )

  const {
    data: dataCreateMonitorSearch,
    isLoading: savingMonitorSearch,
    mutate: mutateCreateMonitorSearch,
    error: errorCreateMonitorSearch,
  } = useCreateMonitorSearch()

  const handleUpdateStep = useCallback((step) => {
    setStep(step)
  }, [])

  const form = useFormik<FormikFormType>({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      name: undefined,
      keywords: search?.keywords,
      buckets: [],
    },
    validationSchema: validationSchemaPainelName,
    onSubmit: ({ buckets, ...settings }) => {
      const payload = {
        ...settings,
        monitors: settings.monitors?.map((monitor) => monitor.key),
        keywords: removeEmptyArrays(settings.keywords),
        systemKeywords: calculatePlurals(removeEmptyArrays(settings.keywords)),
        buckets: Object.keys(search?.buckets).reduce((previous, current) => {
          _.set(previous, current, {
            alert: buckets.includes(current),
            filter: _.get(search, `buckets.${current}.filter`, {}),
          })
          return previous
        }, {}),
      }
      mutateCreateMonitorSearch(payload)
    },
  })

  const handleSubmit = useCallback(() => {
    form.handleSubmit()
  }, [form])

  useEffect(() => {
    if (dataCreateMonitorSearch) {
      form.resetForm()
      showSnackbarSuccess('Pesquisa salva com sucesso')
      closeDrawer()
    }
  }, [dataCreateMonitorSearch])

  useEffect(() => {
    if (errorCreateMonitorSearch) {
      showSnackbarError('Não foi possível salvar a pesquisa')
    }
  }, [errorCreateMonitorSearch])

  useEffect(() => {
    function load() {
      const isIntroductionJump = localStorage.getItem(
        '@NOMOS:save-search-warning'
      )
      if (isIntroductionJump) handleUpdateStep('settings')
    }
    load()
  }, [])

  const store = useMemo(
    () => ({
      step,
      form,
      search,
      activeBucket,
      savingMonitorSearch,
      handleSubmit,
      setActiveBucket,
      handleUpdateStep,
    }),
    [
      step,
      form,
      search,
      activeBucket,
      savingMonitorSearch,
      handleSubmit,
      setActiveBucket,
      handleUpdateStep,
    ]
  )

  return (
    <SaveSearchContext.Provider value={store}>
      {children}
    </SaveSearchContext.Provider>
  )
}
