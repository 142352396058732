import HTTPClient from '../..'
import { searchEvent } from '@utils/metrics'
import { useMutation } from '@tanstack/react-query'
import { AdvancedSearchType } from '@services/nomos_api/entities/advanced_search'
import { removeEmptyArrays } from '@utils/functions/normalizers/remove_empty_array'
import { appendPlurals } from '@utils/functions/keyword/serialize'

export type SearchIrsAPIProps = {
  page: number
  limit: number
  keyword: string
  sort: string
  modes: string[]
  filter: object
  keywords: AdvancedSearchType
}

export const searchIrsAPI = async (props: SearchIrsAPIProps) => {
  searchEvent()
  const params = [`page=${props.page || 1}`, `limit=${props.limit || 10}`]
  if (props.keyword) params.push(`q=${props.keyword}`)
  if (props.sort) params.push(`sort=${props.sort}`)
  if (props.modes?.length > 0) params.push(`modes=${props.modes}`)
  const { data } = await HTTPClient.post(`/search/irs?${params.join('&')}`, {
    ...props.filter,
    keywords: appendPlurals(removeEmptyArrays(props.keywords)),
  })
  return data
}

export const useSearchIrsMutation = () =>
  useMutation(['search-irs'], searchIrsAPI)
