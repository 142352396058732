import HTTPClient from '../..'
import { searchEvent } from '@utils/metrics'
import { useInfiniteQuery, useMutation } from '@tanstack/react-query'
import { AdvancedSearchType } from '@services/nomos_api/entities/advanced_search'
import { removeEmptyArrays } from '@utils/functions/normalizers/remove_empty_array'
import { appendPlurals } from '@utils/functions/keyword/serialize'

export type SearchSocialAPIProps = {
  page: number
  limit: number
  keyword: string
  sort: string
  modes: string[]
  filter: object
  keywords: AdvancedSearchType
}

export const searchSocialAPI = async (props: SearchSocialAPIProps) => {
  searchEvent()
  const params = [`page=${props.page || 1}`, `limit=${props.limit || 10}`]
  if (props.keyword) params.push(`q=${props.keyword}`)
  if (props.sort) params.push(`sort=${props.sort}`)
  if (props.modes?.length > 0) params.push(`modes=${props.modes}`)
  const { data } = await HTTPClient.post(`/search/social?${params.join('&')}`, {
    ...props.filter,
    keywords: appendPlurals(removeEmptyArrays(props.keywords)),
  })
  return data
}

export const useSearchSocialMutation = () =>
  useMutation(['search-social'], searchSocialAPI)

// eslint-disable-next-line
export const useGetSocialByAuthorId = (filter: any, props: any) =>
  useInfiniteQuery(
    ['get-social-author', filter, props],
    ({ pageParam = 1 }) =>
      searchSocialAPI({ filter, page: pageParam, ...props }),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: ({ pagination }) => {
        if (
          pagination.total - pagination.limit > 0 &&
          pagination.page < pagination.total
        ) {
          return pagination.page + 1
        }

        return undefined
      },
    }
  )
