export default {
  camara: 'Câmara dos Deputados',
  senado: 'Senado Federal',
  almg: 'ALMG',
  alesp: 'ALESP',
  aleac: 'ALEAC',
  aleal: 'ALEAL',
  aleam: 'ALEAM',
  alema: 'ALEMA',
  alero: 'ALERO',
  alepi: 'ALEPI',
  alepe: 'ALEPE',
  alepa: 'ALEPA',
  alesc: 'ALESC',
  alego: 'ALEGO',
  alba: 'ALBA',
  alerj: 'ALERJ',
  alrs: 'ALRS',
  aleto: 'ALETO',
  alerr: 'ALERR',
  cldf: 'CLDF',
  'cm-maceio': 'Câmara Municipal de Maceió',
} as Record<string, string>
