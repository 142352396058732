import { Input } from '@components/atoms'
import { OrgansContext } from '../../context'
import { useDebounce } from '@hooks/useDebounce'
import { useEffect, useRef, useState } from 'react'
import { useContextSelector } from 'use-context-selector'
import MultiSelect from '@components/atoms/MultiSelect/MultiSelect'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

import { FiltersContainer } from './styles'

function FilterByName() {
  const firstRender = useRef(true)
  const [name, setName] = useState('')
  const debouncedName = useDebounce(name, 500)

  const handleFilterChange = useContextSelector(
    OrgansContext,
    (state) => state.handleFilterChange
  )

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    handleFilterChange({ q: debouncedName })
  }, [debouncedName])

  return (
    <Input
      size="small"
      type="search"
      id="organ-search-name"
      label="Buscar por nome do orgão"
      value={name}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value)
      }}
    />
  )
}

function FilterByLevel() {
  const firstRender = useRef(true)
  const [levels, setLevel] = useState<string[]>([])

  const handleFilterChange = useContextSelector(
    OrgansContext,
    (state) => state.handleFilterChange
  )

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    handleFilterChange({ level: levels })
  }, [levels])

  return (
    <MultiSelect
      size="small"
      label="Nível"
      options={[
        { label: 'Federal', value: 'federal' },
        { label: 'Municipal', value: 'municipal' },
        { label: 'Estadual', value: 'estadual' },
      ]}
      id="organ-search-resource"
      onChangeOptions={setLevel}
    />
  )
}

function FilterByHouse() {
  const firstRender = useRef(true)
  const [houses, setHouses] = useState<string[]>([])

  const handleFilterChange = useContextSelector(
    OrgansContext,
    (state) => state.handleFilterChange
  )

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    handleFilterChange({ resource: houses })
  }, [houses])

  return (
    <MultiSelect
      size="small"
      label="Casa"
      options={[
        { label: 'Camara dos Deputados', value: 'camara' },
        { label: 'Senado Federal', value: 'senado' },
        { label: 'ALMG', value: 'almg' },
        { label: 'ALESP', value: 'alesp' },
        { label: 'ALEAC', value: 'aleac' },
        { label: 'ALEAL', value: 'aleal' },
        { label: 'ALEAM', value: 'aleam' },
        { label: 'ALEMA', value: 'alema' },
        { label: 'ALERO', value: 'alero' },
        { label: 'ALEPI', value: 'alepi' },
        { label: 'ALEPE', value: 'alepe' },
        { label: 'ALEPA', value: 'alepa' },
        { label: 'ALETO', value: 'aleto' },
        { label: 'ALERR', value: 'alerr' },
        { label: 'ALESC', value: 'alesc' },
        { label: 'ALERJ', value: 'alerj' },
        { label: 'ALRS', value: 'alrs' },
        { label: 'ALEGO', value: 'alego' },
        { label: 'ALBA', value: 'alba' },
        { label: 'CLDF', value: 'cldf' },
        { label: 'Câmara Municipal de Maceió', value: 'cm-maceio' },
      ]}
      id="organ-search-resource"
      onChangeOptions={setHouses}
    />
  )
}

function FilterByPower() {
  const firstRender = useRef(true)
  const [powers, setPowers] = useState<string[]>([])

  const handleFilterChange = useContextSelector(
    OrgansContext,
    (state) => state.handleFilterChange
  )

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    handleFilterChange({ power: powers })
  }, [powers])

  return (
    <MultiSelect
      size="small"
      label="Poderes"
      options={[
        { label: 'Executivo', value: 'executive' },
        { label: 'Legislativo', value: 'legislative' },
      ]}
      id="organ-search-resource"
      onChangeOptions={setPowers}
    />
  )
}

function FilterBySituation() {
  const firstUpdate = useRef(true)
  const [active, setActive] = useState<string>('true')

  const handleFilterChange = useContextSelector(
    OrgansContext,
    (s) => s.handleFilterChange
  )

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    handleFilterChange({ active })
  }, [active])

  return (
    <FormControl fullWidth sx={{ backgroundColor: 'white' }}>
      <InputLabel id="organ-search-situation-label">Situação</InputLabel>
      <Select
        size="small"
        labelId="demo-simple-select-label"
        id="organ-search-situation"
        value={active}
        label="Situação"
        onChange={(e) => setActive(e.target.value)}
      >
        <MenuItem value="">Todos</MenuItem>
        <MenuItem value="true">Ativos</MenuItem>
        <MenuItem value="false">Inativos</MenuItem>
      </Select>
    </FormControl>
  )
}

export default function OrgansFilter() {
  return (
    <FiltersContainer>
      <FilterByName />
      <FilterByLevel />
      <FilterByPower />
      <FilterByHouse />
      <FilterBySituation />
    </FiltersContainer>
  )
}
