import HTTPClient from '../..'
import { searchEvent } from '@utils/metrics'
import { useMutation, useQuery } from '@tanstack/react-query'
import { removeEmptyArrays } from '@utils/functions/normalizers/remove_empty_array'
import { appendPlurals } from '@utils/functions/keyword/serialize'

//* ****************************** *//
//* ** Proposition filter types ** *//
//* ****************************** *//
// - keyword: string
// - page: number
// - limit: number
// - sort: "recently_updated" | "newest" | "older"
// - filter:
//   - casa?: ["camara", "senado"],
//   - orgaos?: Array<string>
//   - tipo?: Array<string>
//   - regime?: Array<string>
//   - resultados?: Array<string>
//   - estados?: Array<string>
//   - partidos?: Array<string>
//   - temas?: Array<string>
//   - autores?: Array<string>
//   - situacao?: Array<string>
//   - tramitacao?: Array<string>
//   - created_at?: { from: Date, to: Date}
//   - updated_at?: { from: Date, to: Date}

const searchPropositionsAPI = async (props) => {
  searchEvent()
  const params = [`page=${props.page || 1}`, `limit=${props.limit || 10}`]
  if (props.keyword) params.push(`q=${props.keyword}`)
  if (props.sort) params.push(`sort=${props.sort}`)
  if (props.modes?.length > 0) params.push(`modes=${props.modes}`)
  const { data } = await HTTPClient.post(
    `/search/propositions?${params.join('&')}`,
    {
      ...props.filter,
      keywords: appendPlurals(removeEmptyArrays(props.keywords)),
      resumeSearch: props.resumeSearch
    }
  )
  return data
}

export const useSearchPropositions = (props) =>
  useQuery(['search_propositions', props], ()=> searchPropositionsAPI(props), {
    refetchOnWindowFocus: false,
  })

export const useSearchPropositionsMutation = () =>
  useMutation(['search-propositions-mutation'], searchPropositionsAPI)
