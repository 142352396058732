import { FormikProps } from 'formik'
import { TextError } from '../TextError'
import { useEffect, useState } from 'react'
import { CardRadio } from '@components/organisms/Cards'
import { Content, InfoLabel } from '../../styles'
import { FormNotificationBucket } from '../FormNotificationBucket'
import { ContainerScroll, TooltipHelper } from '@components/atoms'
import { SavedSearchBucketsEntity } from '@services/nomos_api/entities/saved_searches'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material'

export type SavedSearchFormNotificationProps = {
  form: FormikProps<{
    buckets: string[]
    alert: { frequency: string; destinations: string[] }
  }>
  availableBuckets: SavedSearchBucketsEntity
  disabled?: boolean
}

export function SavedSearchFormNotification({
  form,
  availableBuckets,
  disabled,
}: SavedSearchFormNotificationProps) {
  const [localAlertChannel, setLocalAlertChannel] = useState(
    form.values?.alert?.destinations || ['email']
  )
  const [localAlertFrequency, setLocalAlertFrequency] = useState(
    form.values?.alert?.frequency || 'realtime'
  )

  const handleChannelChange = (
    event: { target: { checked: boolean } },
    channelName: string
  ) => {
    const { checked } = event.target
    if (checked) {
      setLocalAlertChannel([...localAlertChannel, channelName])
    } else {
      setLocalAlertChannel(
        localAlertChannel.filter((channel) => channel !== channelName)
      )
    }
  }

  useEffect(() => {
    form.setFieldValue('alert.destinations', localAlertChannel)
  }, [localAlertChannel])

  useEffect(() => {
    form.setFieldValue('alert.frequency', localAlertFrequency)
  }, [localAlertFrequency])

  return (
    <ContainerScroll
      spacing={24}
      disabled={String(disabled) as 'true' | 'false'}
    >
      <Stack direction="column" spacing={1}>
        <Typography variant="$font-title-5" color="$color-text-secondary">
          Configurações de Alerta
        </Typography>
        <Typography variant="$font-body-base" color="$color-text-secondary">
          Escolha as categorias nas quais deseja ser alertado sempre que um novo
          dado for encontrado. Defina o canal e a frequência de notificação
        </Typography>
      </Stack>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="$font-body-1" color="$color-text-primary">
            Selecione as categorias de dados
          </Typography>
          <TooltipHelper>
            <Stack direction="column" spacing={3}>
              <Typography
                variant="$font-body-base"
                color="$color-text-secondary"
              >
                Escolha as categorias nas quais deseja receber alerta.
              </Typography>
              <Typography
                variant="$font-body-base"
                color="$color-text-secondary"
              >
                Se selecionar apenas &quot;proposição&quot; o sistema irá
                notificar somente sobre os dados encontrados nesta categoria.
              </Typography>
              <Typography
                variant="$font-body-base"
                color="$color-text-secondary"
              >
                Certifique-se de estabelecer filtros para as categorias que
                deseja ser alertado. Isso evitará que receba uma quantidade
                excessiva de notificações.
              </Typography>
            </Stack>
          </TooltipHelper>
        </Stack>
        <FormNotificationBucket
          form={form}
          availableBuckets={availableBuckets}
          disabled={disabled}
        />
      </Stack>
      <div>
        <Typography variant="$font-body-1" color="$color-text-primary">
          Canais de Notificação
        </Typography>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                value="email"
                name="alert.destinations"
                data-testid="email-checkbox"
                checked={localAlertChannel.includes('email')}
                onChange={(e) => handleChannelChange(e, 'email')}
                disabled={disabled}
              />
            }
            label={
              <Typography variant="$font-body-base" color="$color-text-primary">
                Email
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                value="nomos"
                name="alert.destinations"
                data-testid="nomos-checkbox"
                onChange={(e) => handleChannelChange(e, 'nomos')}
                checked
                disabled
              />
            }
            label={
              <Typography variant="$font-body-base" color="$color-text-primary">
                Nomos
              </Typography>
            }
          />
          <FormControlLabel
            sx={{ opacity: 0.5 }}
            control={
              <Checkbox
                value="whatsapp"
                name="alert.destinations"
                data-testid="whatsapp-checkbox"
                checked={false}
                disabled
              />
            }
            label={
              <Typography variant="$font-body-base" color="$color-text-primary">
                WhatsApp (em breve)
              </Typography>
            }
          />
        </FormGroup>
        <TextError
          value={form?.errors?.alert?.destinations as unknown as string}
        />
      </div>
      <Content>
        <InfoLabel>
          <Typography variant="$font-body-1" color="$color-text-primary">
            Frequência de Notificação
          </Typography>
        </InfoLabel>
        <CardRadio
          name="alert.frequency"
          disabled={disabled}
          value={localAlertFrequency}
          onChange={(_e, value) => setLocalAlertFrequency(value)}
          options={[
            {
              title: 'Tempo Real',
              value: 'realtime',
              description:
                'Notificação enviada no momento exato em que a proposição é atualizada',
            },
            {
              title: 'Diário',
              value: 'daily',
              description:
                'Notificação enviada diariamente indicando todas as atualizações da proposição',
            },
            // {
            //   title: 'Semanal',
            //   value: 'weekly',
            //   description:
            //     'Notificação enviada semanalmente com as atualizações desta proposição',
            // },
          ]}
        />
        <TextError
          value={form?.errors?.alert?.frequency as unknown as string}
        />
      </Content>
    </ContainerScroll>
  )
}
