/* eslint-disable no-restricted-syntax */
import { MultiSelectAdvancedOption } from '@components/atoms/InputAdvancedSearch/InputAdvancedSearch'
import { KeywordConditionsType } from '@services/nomos_api/entities/advanced_search'
import { Buffer } from 'buffer'
import plural from 'pluralize-ptbr'

export function encodeKeywords(keywords: KeywordConditionsType): string {
  return Buffer.from(JSON.stringify(keywords)).toString('base64')
}

export function decodeKeywords(keywords: string): KeywordConditionsType {
  try {
    return JSON.parse(
      Buffer.from(keywords, 'base64').toString('utf8')
    ) as KeywordConditionsType
  } catch (error) {
    return { and: [], or: [], not: [] }
  }
}

export function parseKeywordsToInput(
  keywords: KeywordConditionsType
): MultiSelectAdvancedOption[] {
  const search: MultiSelectAdvancedOption[] = []
  for (const keyword of keywords?.or || []) {
    search.push({ kind: 'or', value: keyword })
  }
  for (const keyword of keywords?.and || []) {
    search.push({ kind: 'and', value: keyword })
  }
  for (const keyword of keywords?.not || []) {
    search.push({ kind: 'not', value: keyword })
  }
  return search
}

export function appendPlurals(
  keywords: KeywordConditionsType
): KeywordConditionsType {
  const result: KeywordConditionsType = {
    or: keywords.or,
    and: keywords.and,
    not: keywords.not,
  }

  let keys = Object.assign([], result.or)
  if (result.and) {
    keys = keys.concat(Object.assign([], result.and))
  }
  if (result.not) {
    keys = keys.concat(Object.assign([], result.not))
  }

  if (result.or)
    result.or = result.or.concat(
      result.or
        .map((w) => plural(w))
        .filter((f) => {
          const index = keys.find((v) => v === f)
          if (!index) keys.push(f)
          return index === undefined
        })
    )

  return result
}

export function calculatePlurals(
  keywords: KeywordConditionsType
): KeywordConditionsType {
  let keys = Object.assign([], keywords.or)
  if (keywords.and) {
    keys = keys.concat(Object.assign([], keywords.and))
  }
  if (keywords.not) {
    keys = keys.concat(Object.assign([], keywords.not))
  }

  const result: KeywordConditionsType = {
    or: keywords.or
      ?.map((w) => plural(w))
      .filter((f) => {
        const index = keys.find((v) => v === f)
        if (!index) keys.push(f)
        return index === undefined
      }),
    and: [],
    not: [],
  }

  return result
}
